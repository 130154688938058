<template>
  <div class="inRyad-component">
    <p class="head-title" v-if="parseInt(shipping) == 0">
      توصيل مجاني ولفترة محدودة
    </p>

    <form class="form-full">
      <div class="tel-form" style="text-align: right">
        <label class="form-label">رقم الجوال</label>
        <vue-tel-input
          v-model="auth.phone"
          type="tel"
          :error-messages="phoneErrors"
          v-bind="bindProps"
          required
          @input="$v.auth.phone.$touch()"
          @blur="$v.auth.phone.$touch()"
          @validate="validate($event)"
          ref="mobileInput"
        />
      </div>
      <v-row>
        <v-col cols="12" md="12">
          <!-- <v-text-field
            v-model="shoppingTo.location.address"
            :error-messages="locationErrors"
            class="text-center"
            label="العنوان"
            required
            solo
            @click="openMap"
            @blur="$v.shoppingTo.location.$touch()"
          ></v-text-field> -->
          <div
            class="field-item select-location in-riyadh btn-cart-pop"
            @click="openMap"
          >
            <img
              src="../../assets/images/location.png"
              alt="img"
              width="20px"
              style="margin-left: 5px"
            />
            <span>تحديد العنوان</span><br />
            <span style="display: block; width: 100%; text-align: center">{{
              shoppingTo.location.address
            }}</span>
            <p
              class="map-note"
              id="lableaddress"
              style="border: none; width: 100%; text-align: center; margin: 0"
            ></p>
          </div>
        </v-col>
      </v-row>
      <label class="form-label">كود الخصم</label>
      <v-text-field
        v-model="discount"
        label="كود الخصم"
        solo
        @keyup="checkCouponCode"
        class="discount"
      ></v-text-field>
      <p :style="'margin-bottom:20px;color:' + discountMessageColor">
        {{ discountMessage }}
      </p>
      <!-- <v-select
        :items="payMethods"
        v-model="shoppingTo.paymentMethod"
        :error-messages="paymentErrors"
        required
        solo
        item-value="key"
        item-text="value"
        label="طريقة الدفع"
        @input="$v.shoppingTo.paymentMethod.$touch()"
        @blur="$v.shoppingTo.paymentMethod.$touch()"
      ></v-select> -->
      <label class="form-label">ملاحظات</label>
      <v-textarea v-model="shoppingTo.notes" label="ملاحظات" solo></v-textarea>
    </form>
    <!-- <invoice /> -->
    <div class="invoice-component">
      <div class="paper-head">
        <img src="../../static/paper-head.png" alt="head" />
      </div>
      <div class="paper-item">
        <div class="order-details">
          <div style="text-align: center">
            <vuetify-logo />
          </div>
          <span
            style="
              font-size: 10px;
              font-weight: initial;
              color: #5a5a5a !important;
            "
            >الرقم الضريبي : {{ settings.tax_number }}</span
          >
          <div class="table">
            <v-container id="regular-tables" fluid tag="section">
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="products"
                  item-key="id"
                  :mobile-breakpoint="0"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <div class="name-green">
                      <strong>
                        {{ item.product_name | strippedContent }}</strong
                      >
                    </div>
                  </template>
                  <template v-slot:[`item.quantity`]="{ item }">
                    {{ item.quantity }}
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    {{ item.price }} ريال
                  </template>
                </v-data-table>
              </v-card>
            </v-container>
          </div>
          <hr />
          <div class="footer-reset">
            <p class="total-count">
              <span>الإجمالي </span>
              <span class="total-money left-price">{{ totalAmount }} ريال</span>
            </p>
            <p class="total-count" v-if="discountAmount > 0">
              <span>الخصم </span>
              <span class="total-money left-price"
                >{{ discountAmount }} ريال</span
              >
            </p>
            <p class="total-count" style="">
              <span> التوصيل </span>
              <span class="total-money-delivary left-price"
                >{{ shipping }} ريال</span
              >
            </p>

            <p class="total-count" style="margin-top: 40px">
              <span>المجموع الكلي </span>
              <span class="total-money-all left-price"
                >{{ totalSummation }}
                <span style="font-size: 14px; color: #5a5a5a !important"
                  >ريال</span
                ></span
              >
            </p>
            <br />
            <p class="total-count">
              <span style="color: #5a5a5a; font-size: 9px; font-weight: normal"
                >ضريبة القيمة المضافة ({{ settings.vat }}%)
              </span>
              <span
                class="total-money-tax left-price"
                style="color: #5a5a5a; font-size: 11px; font-weight: normal"
                >{{ VAT }} ريال</span
              >
            </p>
            <!-- <span style="font-size: 10px; font-weight: initial"
              >الرقم الضريبي : 302215788100003</span
            ><br /> -->
          </div>
          <v-btn
            @click="setPass()"
            :disabled="parseInt(totalSummation) == 0"
            class="btn complete-btn"
            >اكمال الطلب</v-btn
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog">
      <v-card>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="buttons">
          <a @click="locateMe" class="btn current-location"
            ><img src="../../static/location.png" alt="location" />تحديد
            موقعى</a
          >
          <button @click="save()" class="btn save">حفظ</button>
          <p class="font">{{ error }}</p>
        </v-card-title>

        <v-card-text>
          <div class="locations">
            <div>
              <div class="map-header">
                <label class="label-auto">
                  <GmapAutocomplete
                    ref="gmapAutocomplete"
                    :position.sync="markers[0].position"
                    @keyup.enter="usePlace"
                    @place_changed="setPlace"
                    :options="{
                      fields: [
                        'geometry',
                        'formatted_address',
                        'address_components',
                      ],
                    }"
                  >
                  </GmapAutocomplete>
                </label>
              </div>
              <br />

              <gmap-map
                :center="center"
                :zoom="10"
                map-type-id="terrain"
                style="width: 100%; height: 250px"
                ref="myMap"
              >
                <gmap-marker
                  @dragend="updateMaker"
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center = m.position"
                ></gmap-marker>
              </gmap-map>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialog1">
      <v-card>
        <v-btn icon dark @click="dialog1 = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="buttons d-flex"> </v-card-title>

        <v-card-text>
          <span>؟هل انت متأكد من هذا الرقم</span>
          <v-text-field
            v-model="auth.phone"
            type="tel"
            solo
            disabled
          ></v-text-field>
          <span>الاسم</span>
          <v-text-field
            v-model="auth.name"
            :error-messages="passwordErrors"
            label="أدخل اسمك"
            type="text"
            solo
            required
            @input="$v.auth.name.$touch()"
            @blur="$v.auth.name.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <button class="btn btn-success" @click="signup()">حفظ</button>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialog1">
      <v-card class="p-5">
        <v-card-title> </v-card-title>
        <v-card-text class="m-auto" dir="rtl">
          هل تريد تأكيد الطلب برقم الجوال
          <span dir="ltr">{{ auth.phone }}</span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="changeMobile()" large>تغيير</v-btn>
          <v-btn @click="signup()" color="success" large>موافق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2">
      <v-card class="p-5">
        <v-card-title> </v-card-title>
        <v-card-text class="m-auto" dir="rtl">
          عفوا المنطقة الحالية خارج الرياض
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog2 = false" color="success" large>موافق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3">
      <v-card class="p-5">
        <v-card-title> </v-card-title>
        <v-card-text class="m-auto" dir="rtl">
          رقم الجوال غير صحيح
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog3 = false" color="success" large>موافق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="success" top right>
      {{ massege }}
    </v-snackbar>
    <v-snackbar v-model="snackbar1" color="red" top right>
      {{ massege }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VuetifyLogo from "../../components/VuetifyLogo.vue";
import { ServiceFactory } from "../../services/ServiceFactory";
import { API_URL } from "../../config";
const service = ServiceFactory.get("register");
// const productService = ServiceFactory.get("product");
const cartService = ServiceFactory.get("cart");
const SettingsService = ServiceFactory.get("settings");
export default {
  mixins: [validationMixin],
  components: {
    VuetifyLogo,
  },
  props: ["settings"],
  validations: {
    auth: {
      phone: {
        required,
        tel: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      },
      name: { required },
    },
    shoppingTo: {
      location: { required },
      paymentMethod: { required },
    },
  },

  data: () => ({
    error: "",
    auth: {
      name: "",
      phone: "",
      user_type: "user",
    },
    shoppingTo: {
      location: {
        address: "",
        lat: null,
        lng: null,
      },
      notes: "",
      paymentMethod: null,
    },
    order_type: "inRyad",
    shipping: 0,
    payMethods: [
      { key: "credit", value: "فيزا" },
      { key: "cash", value: "كاش" },
    ],
    center: { lat: 24.785230000000002, lng: 46.68828739101565 },
    markers: [
      {
        position: { lat: 24.785230000000002, lng: 46.68828739101565 },
      },
    ],
    location: null,
    gettingLocation: false,
    errorStr: null,
    place: null,
    snackbar: false,
    snackbar1: false,
    phoneChanged: false,
    massege: "",
    discount: "",
    VAT: "",
    totalSummation: "",
    dialog: false,
    dialog1: false,
    dialog2: false,
    dialog3: false,
    categories: [],
    formCards: [],
    totalAmount: null,
    discountAmount: 0,
    discountMessage: "",
    discountMessageColor: "red",
    phoneValid: false,

    bindProps: {
      mode: "international",
      disabledFetchingCountry: false,
      disabledFormatting: false,
      defaultCountry: "SA",
      placeholder: "أدخل رقم الهاتف",
      required: true,
      enabledCountryCode: false,
      type: "tel",
      formattedNumber: true,
      autocomplete: "on",
      autofocus: true,
      onlyCountries: ["SA", "UAE", "KW", "OM", "BH", "EG"],
      name: "telephone",
      validCharactersOnly: true,
      invalidMsg: "Invalid",
      dropdownOptions: {
        disabledDialCode: true,
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
      },
      inputOptions: {
        // showDialCode: true,
        type: "tel",
        placeholder: "رقم الجوال",
      },
    },

    headers: [
      { text: "الاسم", value: "name", sortable: false },
      { text: "الكمية", value: "quantity", sortable: false },
      { text: "سعر الوحدة", value: "price", sortable: false },
    ],
  }),

  computed: {
    ...mapGetters(["total"]),
    products() {
      return this.$store.state.products;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.auth.phone.$dirty) return errors;
      !this.$v.auth.phone.required && errors.push("يجب ادخال رقم الهاتف");
      return errors;
    },
    paymentErrors() {
      const errors = [];
      if (!this.$v.shoppingTo.paymentMethod.$dirty) return errors;
      !this.$v.shoppingTo.paymentMethod.required &&
        errors.push("يجب اختيار طريقة دفع");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.auth.name.$dirty) return errors;
      !this.$v.auth.name.required && errors.push("يجب ادخال اسمك");
      return errors;
    },
    locationErrors() {
      const errors = [];
      if (!this.$v.shoppingTo.location.$dirty) return errors;
      !this.$v.shoppingTo.location &&
        errors.push("يجب ان يكون العنوان داخل الرياض");
      !this.$v.shoppingTo.location.required && errors.push("يجب تحديد العنوان");
      return errors;
    },
    discountErrors() {
      const errors = [];
      if (!this.$v.discount.$dirty) return errors;
      !this.$v.discount.minLength && errors.push("يجب ادخال كود خصم صالح");
      return errors;
    },
  },
  created() {
    this.getShipping();
    this.totalMoneyInInvoice();
    this.vat();

    if (localStorage.getItem("mobile") != undefined) {
      this.auth.phone = localStorage.getItem("mobile");
    }
    // eslint-disable-next-line no-undef
    $(document).ready(function () {
      // eslint-disable-next-line no-undef
      $(".discount .v-text-field__details").remove();
    });
  },
  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },

  methods: {
    async getShipping() {
      const settings = await SettingsService.getShippingPrice();
      console.log(settings);
      this.shipping = settings.shipping_price_in_ryad;
      this.totalSummations();
    },
    validate(event) {
      this.phoneValid = event.valid;
    },
    changeMobile() {
      this.dialog1 = false;
      this.phoneChanged = true;
      window.scrollTo(0, this.$refs.mobileInput.offsetTop);
      setTimeout(() => {
        document.querySelector(".vti__input").focus();
      }, 1000);
    },
    checkCouponCode() {
      this.discountMessage = "";
      const resource = `${API_URL}/checkout/checkCoupon?coupon_code=`;
      this.$http
        .post(
          resource,
          {
            products: this.products,
            subTotal: this.totalAmount,
            coupon_code: this.discount,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            if (response.data.data.valid) {
              this.discountMessageColor = "green";
              this.discountMessage =
                "كوبون صالح وتم تطبيق خصم بقيمة " +
                response.data.data.amount +
                " ريال سعودي";
              this.discountAmount = response.data.data.amount;
              // this.totalAmount = this.totalAmount - response.data.data.amount;
            } else {
              this.discountAmount = 0;
              this.discountMessageColor = "red";
              this.discountMessage = response.data.data.message;
            }
            this.vat();
            this.totalSummations();
          }
          this.dataLoading = false;
        });
    },
    save() {
      if (this.shoppingTo.location.address.length > 0) {
        if (
          this.shoppingTo.location.address.includes("Riyadh") ||
          this.shoppingTo.location.address.includes("riyadh") ||
          this.shoppingTo.location.address.includes("رياض")
        ) {
          this.dialog = false;
          this.error = "";
        } else {
          this.shoppingTo.location.address = "";
          this.dialog2 = true;
          // eslint-disable-next-line no-undef
          $(".pac-target-input").val("");
        }
      } else {
        this.error = "يجب تحديد العنوان";
      }
    },
    closeDialog() {
      this.dialog = false;
      this.shoppingTo.location.address = "";
      this.shoppingTo.location.lat = null;
      this.shoppingTo.location.lng = null;
      // eslint-disable-next-line no-undef
      $(".pac-target-input").val("");
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
            this.updateMyMaker(pos.coords);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
        this.center.lat = this.location.coords.latitude;
        this.center.lng = this.location.coords.longitude;

        this.markers[0].position.lat = this.location.coords.latitude;
        this.markers[0].position.lng = this.location.coords.longitude;

        this.shoppingTo.location.lat = this.location.coords.latitude;
        this.shoppingTo.location.lng = this.location.coords.longitude;
      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }
    },
    setPlace(place) {
      this.place = place;
      this.usePlace(place);
    },
    setDescription(description) {
      this.description = description;
    },
    usePlace(place) {
      console.log(place);
      if (this.place) {
        var newPostion = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.center = newPostion;
        this.markers[0].position = newPostion;
        this.shoppingTo.location.lat = newPostion.lat;
        this.shoppingTo.location.lng = newPostion.lng;

        this.shoppingTo.location.address = this.place.formatted_address;
        this.place = null;
      }
    },
    updateMyMaker(event) {
      this.markers[0].position = {
        lat: event.latitude,
        lng: event.longitude,
      };
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { latLng: this.markers[0].position },
        (result, status) => {
          console.log(result);
          this.shoppingTo.location.address = result[0].formatted_address;
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK) {
            this.$refs.gmapAutocomplete.$refs.input.value =
              result[0].formatted_address;
            this.shoppingTo.location.address = result[0].formatted_address;
          }
        }
      );
    },

    updateMaker(event) {
      this.markers[0].position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: event.latLng }, (result, status) => {
        console.log(result);
        // eslint-disable-next-line no-undef
        if (status === google.maps.GeocoderStatus.OK) {
          this.$refs.gmapAutocomplete.$refs.input.value =
            result[0].formatted_address;
          this.shoppingTo.location.address = result[0].formatted_address;
          console.log("heeeeeeeeeeeeee");
          this.shoppingTo.location.lat = this.markers[0].position.lat;
          this.shoppingTo.location.lng = this.markers[0].position.lng;
        }
      });
    },

    totalMoneyInInvoice() {
      this.totalAmount = this.products.map(
        (item) => (item.total = item.quantity * item.price)
      );
      this.totalAmount = this.products.reduce((n, { total }) => n + total, 0);
    },
    vat() {
      this.VAT =
        this.discountAmount > 0
          ? ((this.totalAmount - this.discountAmount) *
              parseInt(this.settings.vat)) /
            100
          : (this.totalAmount * parseInt(this.settings.vat)) / 100;
    },
    totalSummations() {
      this.totalSummation =
        this.discountAmount > 0
          ? Math.ceil(
              this.totalAmount -
                this.discountAmount +
                this.VAT +
                parseInt(this.shipping)
            )
          : Math.ceil(this.totalAmount + this.VAT + parseInt(this.shipping));
    },
    openMap() {
      this.dialog = true;
    },
    submit() {
      this.$v.$touch();
    },
    setPass() {
      if (this.phoneValid) {
        if (
          this.auth.phone.length > 0 &&
          this.shoppingTo.location.address.length > 0
        ) {
          this.dialog1 = true;
        } else {
          this.massege = " يجب ملئ الحقول المطلوبة";
          this.snackbar1 = true;
        }
      } else {
        this.dialog3 = true;
      }
      // console.log(this.$refs.mobileInput);
    },
    async signup() {
      this.dataLoading = true;

      const auth = {
        name: this.auth.name,
        mobile: this.auth.phone.split(" ").join(""),
      };
      if (auth.mobile != localStorage.mobile) {
        this.phoneChanged = true;
      }
      this.$store.commit("addPhone", {
        phone: this.auth.phone.split(" ").join(""),
      });
      console.log({
        location: this.shoppingTo.location,
        notes: this.shoppingTo.notes,
        paymentMethod: this.shoppingTo.paymentMethod,
        discount: this.discount,
        order_type: this.order_type,
        totalPrice: this.totalSummation,
      });
      this.$store.commit("addShoppingData", {
        location: this.shoppingTo.location,
        notes: this.shoppingTo.notes,
        paymentMethod: this.shoppingTo.paymentMethod,
        discount: this.discount,
        order_type: this.order_type,
        totalPrice: this.totalSummation,
      });
      if (localStorage.token && !this.phoneChanged) {
        const allData = {
          location: this.shoppingTo.location,
          notes: this.shoppingTo.notes,
          products: this.products,
          shipping_to: this.shipping_to,
          order_type: this.order_type,
          payment_method: this.paymentMethod,
          coupon_code: this.discount,
        };
        console.log(allData);
        const completeCart = await cartService.checkOut(allData);
        // console.log(completeCart);
        if (completeCart.status.error === false) {
          // this.$router.push(
          //   "/paymentMethod?orderId=" + completeCart.data.order_id
          // );
          window.location =
            "/paymentMethod?orderId=" + completeCart.data.order_id;
        } else if (completeCart.status === 401) {
          // console.log("here");
          const signup = await service.signup(auth);
          if (signup.status.error == false) {
            this.$router.push("/confirm");
          } else {
            this.massege = signup.status.message;
            this.snackbar = true;
          }
          this.dialog1 = false;

          this.dataLoading = false;
        } else {
          this.message = completeCart.status.message;
          this.snackbar1 = true;
        }
      } else {
        const signup = await service.signup(auth);
        if (signup.status.error == false) {
          this.$router.push("/confirm");
        } else {
          this.massege = signup.status.message;
          this.snackbar = true;
        }
        this.dialog1 = false;

        this.dataLoading = false;
      }
    },
  },
};
</script>
<style>
.font {
  font-size: 14px;
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.map-header input {
  width: 100%;
  border: 0.5px solid #000;
  padding: 7px;
  border-radius: 7px;
}
.inRyad-component .head-title {
  text-align: center;
  font-size: 14px;
}
.v-text-field.v-text-field--solo .v-label {
  left: auto !important;
  right: 0 !important;
}
.v-dialog {
  background-color: #fff;
  max-width: 550px;
  padding: 0 18px 18px;
  border-radius: 11px;
}
.v-dialog iframe {
  width: 100%;
  margin-top: 20px;
  height: 250px;
}
.theme--dark.v-btn.v-btn--icon {
  color: #5a5a5a;
  font-size: 38px;
}
.v-dialog .buttons .current-location {
  text-align: center;
  border-radius: 12px;
  justify-content: center;
  color: #444;
  text-decoration: none;
  border: 2px solid #fed24e;
  font-weight: 600;
  margin: 0 0 2px 10px;
  padding: 11px 25px;
  width: calc(50% - 10px);
}
.v-dialog .buttons .current-location img {
  width: 20px;
  height: 20px;
  margin: auto;
}
.v-dialog .buttons .save {
  background-color: #fed24e !important;
  border: 2px solid #fed24e;
  color: #424242 !important;
  text-align: center;
  border-radius: 12px;
  font-weight: 600;
  margin: 0 10px 2px 0px;
  padding: 11px 25px;
  width: calc(50% - 10px);
}
.v-dialog .buttons .save:focus {
  border: 2px solid #fed24e;
  box-shadow: unset;
  outline: unset;
}
.v-application--is-ltr .v-messages {
  text-align: right;
}
.invoice-component {
  width: 100%;
}
.invoice-component .paper-head {
  margin-bottom: 10px;
  position: relative;
}
.invoice-component .paper-head img {
  width: 100%;
}
.invoice-component .paper-head::after {
  content: "";
  position: absolute;
  background-image: url(../../static/paper.png);
  bottom: -17px;
  right: 7.3%;
  left: 7.3%;
  height: 23px;
  background-size: 21px;
  background-repeat: repeat-x;
}
.theme--light.v-data-table .v-data-footer {
  display: none;
}
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  text-align: right;
}
.name-green {
  color: #4c9e53;
}
.invoice-component .paper-item {
  background-color: #fff;
  margin: 0 7.3%;
  padding: 5px 10px 15px;
  position: relative;
}
.invoice-component .paper-item::after {
  content: "";
  position: absolute;
  background-image: url(../../static/paper.png);
  top: -22px;
  right: 0;
  left: 0;
  height: 23px;
  background-size: 21px;
  background-repeat: repeat-x;
  transform: rotate(180deg);
}
.complete-btn {
  display: block !important;
  text-align: center !important;
  background-color: #4c9e53 !important;
  color: #fff !important;
  border-radius: 37px;
  padding: 9px !important;
  cursor: pointer !important;
  text-decoration: none;
  width: 100% !important;
  border: none !important;
  font-family: "frutiger lt arabic 55 roman" !important;
  height: auto !important;
}
.tel-form {
  direction: ltr !important;
  text-align: left;
  max-width: 600px;
  margin-bottom: 30px !important;
}
.tel-form input {
  border-radius: 7px;
  font-size: 14px;
  max-width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  background-color: #fff;
}
.footer-reset {
  margin-bottom: 11px;
}
.footer-reset .total-count {
  margin: 5px 0;
}
.left-price {
  float: left;
  position: relative;
  left: 56px;
}
.total-money-all {
  font-weight: 600;
  font-size: 24px;
  color: #5fad66;
}
.v-data-table__wrapper .text-start {
  padding: 0 !important;
  text-align: center !important;
}
.v-data-table-header .text-start {
  text-align: center !important;
}
</style>
